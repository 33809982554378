/* eslint-disable no-param-reassign */
import { getServiceDependencyList } from '@/api/servers';
import { getServiceShowName } from '@/views/service-management/components/utils';
import { ref } from 'vue';

export const associatedList = ref([] as any);

const serviceStatus: any = {
  1: '构建中',
  2: '构建失败',
  3: '构建超时',
  10: '有效',
};
export function getServiceAssociated(id?: number, serviceName?: string) {
  console.log('get service dependency');
  return getServiceDependencyList({ serviceId: id }).then((res) => {
    if (res?.data) {
      const { data = [] } = res;
      const serviceList = data.map((i: any) => ({
        label: getServiceShowName(i.serviceName),
        value: i.serviceName,
        children: i.versions?.map((v: any) => ({
          value: v.version,
          label: v.versionStatus !== 10 ? `${v.version}(${serviceStatus[v.versionStatus]})` : v.version,
          versionType: v.versionType,
          disabled: v.versionStatus !== 10,
        })),
      }));
      const list = serviceList.filter((i: any) => i.value !== serviceName);
      associatedList.value = list;
    } else {
      associatedList.value = [];
    }
  });
}
export function getServiceVersionType(name: string, version: string) {
  const { children: serversVersion = [] } = associatedList.value.find((service: any) => service.value === name);
  const versionData = serversVersion.find((v: any) => v.value === version) || {};
  return versionData.versionType;
}

export const appTemplateTypes: any = [
  {
    name: 'PC端web子页面模板',
    code: 0,
  },
  {
    name: '移动端H5子页面模板',
    code: 1,
  },
];
export const mainAppTemplateTypes: any = [
  {
    name: 'PC端web主应用模板',
    code: 0,
  },
  {
    name: '移动端H5主应用模板',
    code: 1,
  },
];

// 应用模版类型
export enum templateEnum {
  'PC_TEMPLATE',
  'H5_TEMPLATE',
}

// 应用级别
export enum appLevelEnum {
  // 主应用
  MAIN_APP = 0,
  // 微应用
  MICRO_APP = 1,
}
