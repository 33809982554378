<template>
  <div class="iframe-div">
    <div class="refresh-box">
      <div class="refresh-input">
        <input v-model="url" type="text" readonly="readonly" />
      </div>
      <div class="refresh-tools">
        <i @click="refresh(currentApp.status)" class="el-icon-refresh" :class="refreshState ? 'rotate' : ''"></i>
        <i @click="fullScreen(currentApp.status)" class="new-windows">
          <svg
            t="1637913103163"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1573"
            width="200"
            height="200"
          >
            <path :d="paths" p-id="1574"></path>
          </svg>
        </i>
      </div>
    </div>
    <div class="iframe-wrapper" :class="{ 'mobile-iframe': currentApp.templateType === 1 }">
      <iframe :src="url" ref="iframeRef" v-if="currentApp.status > 20"></iframe>
      <div class="other-data" v-else>该应用未启动</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue';
import { currentApp } from '@/views/application-module/utils/dataCenter';

export default defineComponent({
  name: 'IframeDiv',
  props: {
    src: {
      type: String,
      default: () => '',
    },
    status: {
      type: Number,
      default: () => 10,
    },
  },
  data() {
    return {
      url: null,
      refreshState: false,
    };
  },
  created() {
    this.url = this.getUrl();
  },
  methods: {
    refresh(status) {
      if (status > 20) {
        if (!this.refreshState) {
          this.refreshState = true;
          setTimeout(() => {
            this.refreshState = false;
            const urlArr = this.url.split('?');
            let param = urlArr.length > 1 ? urlArr[1] : '';
            const paramArr = !param ? [] : param.split('&');
            if (paramArr.length > 0 && paramArr[paramArr.length - 1].indexOf('_v') === 0) {
              // 去掉_v重新添加
              param = param.substring(0, param.indexOf('&_v=') + 1);
            }
            this.url = `${urlArr[0]}?${param}_v=${new Date().getTime()}`;
          }, 500);
        }
      }
    },
    fullScreen(status) {
      if (status > 20) {
        window.open(this.url, '_blank');
      }
    },
  },
  setup() {
    // eslint-disable-next-line
    const paths = 'M654.601143 708.8a36.964571 36.964571 0 0 0 26.157714-10.843429 37.046857 37.046857 0 0 0 10.843429-26.185142V434.468571H454.555429a36.992 36.992 0 0 0-32.594286 18.322286 37.074286 37.074286 0 0 0 0 37.421714 36.992 36.992 0 0 0 32.594286 18.322286h110.774857l-44.525715 44.580572-155.565714 155.803428a37.056 37.056 0 0 0 16.274286 62.573714 36.955429 36.955429 0 0 0 36.022857-10.221714l155.693714-155.867428 44.489143-44.544v110.912a37.010286 37.010286 0 0 0 36.882286 37.028571z m313.92-450.834286h-0.393143V227.84C968.128 132.708571 890.368 54.857143 795.282286 54.857143H227.657143C132.608 54.857143 54.857143 132.708571 54.857143 227.84v568.265143c0 95.186286 77.750857 172.982857 172.8 172.982857h567.625143c95.085714 0 172.8-77.833143 172.8-172.982857v-209.92a38.162286 38.162286 0 0 0-38.034286-37.202286 38.162286 38.162286 0 0 0-38.034286 37.211429v209.92c-0.246857 53.385143-43.401143 96.603429-96.731428 96.877714H227.657143c-53.321143-0.274286-96.484571-43.492571-96.731429-96.886857V331.547429h837.604572v-73.581715z m-76.452572 0H130.925714V227.84c0.274286-53.366857 43.419429-96.557714 96.731429-96.832h567.625143c53.312 0.274286 96.457143 43.465143 96.731428 96.832l0.054857 30.125714z m-80.950857-82.368H475.245714c-9.846857 0-17.929143 8.411429-17.929143 18.678857v3.291429c0 10.267429 8.082286 18.678857 17.929143 18.678857h335.872c9.856 0 17.929143-8.411429 17.929143-18.688v-3.282286c0-10.267429-8.073143-18.678857-17.929143-18.678857z m-488.850285-2.642285a22.994286 22.994286 0 0 0-16.219429 39.222857 22.930286 22.930286 0 0 0 39.168-16.256 22.966857 22.966857 0 0 0-22.948571-22.966857z m69.522285 0a22.994286 22.994286 0 0 0-16.219428 39.222857 22.930286 22.930286 0 0 0 39.177143-16.256 22.985143 22.985143 0 0 0-22.976-22.966857h0.018285z'
    const srcs = ref('');
    const iframeRef = ref(null);
    const getPreviewName = (name = '') => name?.replace(/\./g, '-');
    watch(currentApp, (nn, oo) => {
      if (!nn) {
        return;
      }
      const { host, origin } = location;
      const tenetName = host.split('.')[0];
      if (nn.id !== oo.id) {
        srcs.value = origin.replace(tenetName, `${getPreviewName(nn.previewUrn)}.preview`);
      }
      if (oo.status <= 20 && nn.status > 20) {
        // iframeRef.value.contentWindow.location.reload();
      }
    });
    onMounted(() => {
      const { host, origin } = location;
      const tenetName = host.split('.')[0];
      if (currentApp.value.name) {
        srcs.value = origin.replace(tenetName, `${getPreviewName(currentApp.value.previewUrn)}.preview`);
      }
    });
    const getUrl = () => srcs;
    return {
      iframeRef,
      currentApp,
      getUrl,
      paths,
    };
  },
});
</script>

<style lang="scss" scoped="scoped">
.iframe-div {
  width: 100%;
  height: 100%;

  //calc(100vh - 150px);
  iframe {
    width: 100%;
    height: calc(100% - 50px);
    margin: 0;
    padding: 0;
    border: none;
  }
  .iframe-wrapper {
    height: 100%;
  }
  .mobile-iframe {
    iframe {
      height: 100%;
    }
    margin: 100px auto 30px auto;
    height: 667px;
    width: min(75vw, 375px);
    border-radius: 6px;
    border: 1px solid #ddd;
    outline: transparent solid 9999px;
  }

  .other-data {
    width: 100%;
    height: 100%;
    line-height: 800px;
    text-align: center;
  }

  .refresh-box {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    height: 48px;
    border-bottom: 2px solid #eee;

    .refresh-input {
      float: left;
      width: calc(100% - 88px);
      height: 35px;

      input {
        border: none;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 6px inset #ccc;
        padding-left: 20px;
        color: #666;
      }

      input:focus-visible {
        outline: none;
      }
    }

    .refresh-tools {
      width: 88px;
      float: left;
      box-sizing: border-box;

      i {
        font-size: 28px;
        line-height: 35px;
        margin-left: 12px;
        cursor: pointer;
      }
      .new-windows {
        position: relative;
        svg {
          position: absolute;
          top: 7px;
          width: 26px;
          height: 26px;
        }
      }

      .el-icon-full-screen {
        font-size: 26px;
      }
    }
  }

  .rotate {
    animation: rotate 1s linear infinite;
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(359deg);
    }

    to {
      -moz-transform: rotate(0deg);
    }
  }

  @-o-keyframes rotate {
    from {
      -o-transform: rotate(359deg);
    }

    to {
      -o-transform: rotate(0deg);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(359deg);
    }

    to {
      transform: rotate(0deg);
    }
  }
}
</style>
