<template>
  <el-dialog title="发版" v-model="visible" @close="handleCloseDialog" :close-on-click-modal="false" destroy-on-close>
    <div class="release-container">
      <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
        <el-form-item label="版本" prop="appVersion">
          <VersionInput v-model="form.appVersion" :lastVersion="lastVersion"></VersionInput>
        </el-form-item>
        <el-form-item label="发版说明" prop="description">
          <el-input
            type="textarea"
            v-model="form.description"
            placeholder="请输入发版说明"
            :rows="10"
            :autosize="{ maxRows: 16, minRows: 10 }"
          ></el-input>
        </el-form-item>
      </el-form>

      <div class="dialog-footer" style="margin-top: 20px">
        <el-button type="primary" @click="handleSubmit" :loading="finishing">确定</el-button>
        <el-button @click="handleCloseDialog">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { defineComponent, reactive, ref } from 'vue';
import VersionInput from '../../service-management/business-service/components/VersionInput.vue';
import { compare, diff, parse, Relation, valid } from '../../service-management/business-service/components/version';
import { getLatestAppInfo, publishAppVersion } from '@/api/app';
import { ElMessage } from 'element-plus';

const checkVerion = (value, callback, lastVersion) => {
  // 首次版本从1.0.0 开始
  if (value === '') {
    callback(new Error('版本号不能为空'));
  }
  if (!valid(value)) {
    callback(new Error('不合法的版本号'));
  }
  if (lastVersion) {
    if (compare(value, lastVersion) === Relation.Equal) {
      callback(new Error('不能等于上个版本号'));
    }
    if (compare(value, lastVersion) === Relation.Less) {
      callback(new Error('不能小于上个版本号'));
    }
    // 主版本号跨度不能大于1
    if (diff(value, lastVersion, 'major') > 1) {
      callback(new Error('主版本与上一版本跨度不能大于 1'));
    }
  } else {
    // 初始版本比较主版本必须为1
    if (parse(value)?.major !== 1) {
      callback(new Error('初始版本主版本必须为 1'));
    }
  }
  callback();
};

export default defineComponent({
  name: 'ReleaseDialog',
  components: { VersionInput },
  setup() {
    const visible = ref(false);
    const finishing = ref(false);
    const formRef = ref(null);
    const lastVersion = ref('');
    const appInfo = ref(null);
    const form = reactive({
      appVersion: '1.0.0',
      description: '',
    });
    const rules = {
      appVersion: [
        { required: true, message: '请输入版本号', trigger: 'blur' },
        {
          validator: (rule, value, callback) => {
            checkVerion(value, callback, lastVersion.value);
          },
          trigger: 'blur',
        },
      ],
      description: [
        {
          required: true,
          message: '请输入使用注意事项，更新日志，版本信息、bug修复记录',
          trigger: 'blur',
        },
        {
          min: 1,
          max: 2048,
          message: '长度在 1 到 2048 个字符',
          trigger: 'blur',
        },
      ],
    };

    // 获取上个版本
    const getLastetVersion = async () => {
      const { data } = await getLatestAppInfo(appInfo.value.id);
      lastVersion.value = data?.appVersion || '';
    };

    // 打开对话框，初始化
    const handleOpenDialog = (app) => {
      appInfo.value = app;
      getLastetVersion();
      visible.value = true;
    };

    // 关闭
    const handleCloseDialog = () => {
      visible.value = false;
      finishing.value = false;
      formRef.value.resetFields();
    };

    // 表单提交
    const handleSubmit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return;
      }
      finishing.value = true;
      try {
        await publishAppVersion({
          appId: appInfo.value.id,
          appVersion: form.appVersion,
          description: form.description,
        });
        ElMessage.success('发版成功');
        handleCloseDialog();
      } catch (e) {
        console.log(e);
      } finally {
        finishing.value = false;
      }
    };

    return {
      visible,
      finishing,
      form,
      rules,
      formRef,
      lastVersion,
      handleOpenDialog,
      handleCloseDialog,
      handleSubmit,
    };
  },
});
</script>
<style lang="scss" scoped>
.dialog-footer {
  display: block;
  text-align: center;
}
</style>
