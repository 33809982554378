
import { computed, defineComponent, getCurrentInstance, PropType, ref, Ref, SetupContext, watch } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { SuccessResponse } from '@/types/response';
import { updateAppById } from '@/api/app';
import {
  allService,
  getAllService,
  deleteBlankArray,
} from '@/views/service-management/business-service/utils/service-data-utils';
import { getShowBool } from '@/utils/permission-show-module';
import { currentApp, getDetailApp } from '@/views/application-module/utils/dataCenter';
import { useRoute } from 'vue-router';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { getServiceShowName } from '@/views/service-management/components/utils';
import { getTemplateTShowName } from '../utils/utils';
import { getServiceDependencyList } from '@/api/servers';
import { getAllTags } from '@/api/settings/tags';

import { getClassificationList } from '@/api/settings/classification';

interface DetailInterface {
  id: number;
  name: string;
  description: string;
  remark: string;
  thumbnail: string;
  services: any[];
  imageUrl: string;
}

export default defineComponent({
  name: 'ApplicationDetail',
  inheritAttrs: false,
  components: {
    ServiceName,
  },
  props: {
    visable: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Object as PropType<DetailInterface>,
      default: () => ({}),
    },
    id: {
      type: String,
      default: () => '',
    },
    appType: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props: { visable: boolean; detail: DetailInterface }, ctx: SetupContext) {
    const route = useRoute();
    // const router = useRouter();
    const appLabel = computed(() => {
      if (currentApp.value.level === 0) {
        return '主应用';
      }
      return '微模块';
    });
    const id = String(route.params.serviceId || route.params.id);
    if (!id) {
      return;
    }
    const instance = getCurrentInstance();
    getDetailApp(id);
    const detailInfo: Ref<DetailInterface> = ref(currentApp.value);

    detailInfo.value.imageUrl = currentApp.value.thumbnail;
    const isEditable: Ref<boolean> = ref(false);
    const submitting = ref(false);

    const tags: any = ref([]);
    const tagValue: any = ref([]);

    // 获取所有标签
    const getTags = async () => {
      const { data } = await getAllTags();
      tags.value.push(...(data || []));
    };

    getTags();

    const classifications: any = ref([]);
    const classificationValue: any = ref([]);

    // 获取所有分类信息
    const getClassifications = async () => {
      const { data } = await getClassificationList();
      const item = { children: data };
      deleteBlankArray(item);
      classifications.value = item.children || [];
    };

    getClassifications();

    const isVisible: any = computed(() => props.visable);
    // 每次打开会保留上一次的数据，需要重置
    const onOpen = () => {
      detailInfo.value = props.detail;
      isEditable.value = false;
    };
    getAllService();

    // 服务依赖组件抽离区域
    const dependList = ref([]);
    const serviceStatus: any = {
      1: '构建中',
      2: '构建失败',
      3: '构建超时',
      10: '有效',
    };
    getServiceDependencyList({}).then((res) => {
      if (res?.data) {
        const { data = [] } = res;
        const serviceList = data.map((i: any) => ({
          label: getServiceShowName(i.serviceName),
          value: i.serviceName,
          children: i.versions?.map((v: any) => ({
            value: v.version,
            label: v.versionStatus !== 10 ? `${v.version}(${serviceStatus[v.versionStatus]})` : v.version,
            versionType: v.versionType,
            disabled: v.versionStatus !== 10,
          })),
        }));
        dependList.value = serviceList.filter((i: any) => i.value !== '');
      } else {
        dependList.value = [];
      }
    });
    const dependencyNameAndVersion = (data: any) => {
      const { dependencyServiceName, dependencyServiceVersion } = data;
      return `${getServiceShowName(dependencyServiceName)}/${dependencyServiceVersion}`;
    };
    const editDepend = ref([]) as any;
    const nodeChange = (nodes: any) => {
      const checkNode: any = {};
      for (const node of nodes) {
        checkNode[node[0]] = node;
      }
      const selectData = Object.values(checkNode);
      editDepend.value = selectData;
    };
    watch(isEditable, (nn) => {
      if (nn) {
        editDepend.value = ((detailInfo.value as any).dependencyServices || []).map((x: any) => [
          x.dependencyServiceName,
          x.dependencyServiceVersion,
        ]);
        tagValue.value = currentApp.value.tag ? currentApp.value.tag.split(',').map((x: string) => +x) : '';
        // eslint-disable-next-line prefer-destructuring
        classificationValue.value = (currentApp.value.classification || '').split(',').map((x: string) => Number(x))[0];
      }
    });
    const findService = (arrs: any) => {
      const mapData: any = {};
      const result: any = [];
      dependList.value.forEach((x: any) => {
        mapData[x.value] = x.children;
      });
      const arr = Object.values(arrs);
      arr.forEach((xs: any) => {
        const x = Object.values(xs);
        // eslint-disable-next-line
        // @ts-ignore
        const versions = mapData[x[0]] || [];
        versions.forEach((y: any) => {
          if (y.value === x[1]) {
            result.push({
              serviceName: x[0],
              serviceVersion: x[1],
              serviceVersionType: y.versionType,
            });
          }
        });
      });
      console.log(result);
      return result;
    };

    // 到此结束
    const serviceIds = ref(detailInfo.value.services?.map((item: any) => item.serviceId));

    const computedServicesName = computed(() => {
      const services = allService.value.filter((item: any) => serviceIds.value?.includes(item.id));
      return services.map((service: any) => service.name);
    });

    const logoUploadError = () => {
      (instance as any).proxy.$message({
        type: 'error',
        message: '上传失败，请重新上传！',
      });
    };

    const logoUploadSuccess = (res: SuccessResponse<any>, file: { raw: any }) => {
      if (res.code === 0 && res.data?.fileKey) {
        detailInfo.value.thumbnail = res.data.fileKey;
        detailInfo.value.imageUrl = URL.createObjectURL(file.raw);
      } else {
        logoUploadError();
      }
    };

    const beforeUpload = (file: { size: number }) => {
      if (file.size > 1024 ** 2 * 3) {
        (instance as any).proxy.$message({
          type: 'warning',
          message: '上传图片大小不能超过3M',
        });
        return false;
      }
    };

    const updateAppDetail = async () => {
      submitting.value = true;
      const payload = {
        ...detailInfo.value,
        ...{ services: findService(editDepend.value) },
        tag: tagValue.value?.join(',') || '',
        classification: classificationValue.value?.toString() || '',
      };
      try {
        const { code } = await updateAppById(detailInfo.value.id, payload);
        if (code === 0) {
          (instance as any).proxy.$message({
            type: 'success',
            message: '模块更新成功',
          });
          isEditable.value = false;
          ctx.emit('close');
          await getDetailApp(id);
          detailInfo.value = currentApp.value;
          detailInfo.value.imageUrl = currentApp.value.thumbnail;
        }
        submitting.value = false;
      } catch (e) {
        console.log(e);
        submitting.value = false;
      }
    };

    const handleCloseDialog = (type: string) => {
      isEditable.value = false;
      ctx.emit('close', type);
    };

    const logs = (res: any) => {
      console.log(res, 'this is res');
      return res;
    };

    return {
      IMAGE_UPLOAD,
      appLabel,
      allService,
      serviceIds,
      computedServicesName,
      isVisible,
      isEditable,
      detailInfo,
      logoUploadError,
      logoUploadSuccess,
      beforeUpload,
      updateAppDetail,
      handleCloseDialog,
      getShowBool,
      onOpen,
      submitting,
      getServiceShowName,
      dependList,
      dependencyNameAndVersion,
      nodeChange,
      editDepend,
      tags,
      classifications,
      tagValue,
      classificationValue,
      logs,
      getTemplateTShowName,
      currentApp,
    };
  },
});
