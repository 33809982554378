import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock("div", {
    class: "verion-input__container",
    key: _ctx.renderKey
  }, [
    _createVNode(_component_el_input_number, {
      class: "verion-input__item",
      modelValue: _ctx.localValue.major,
      min: 1,
      max: 999,
      controls: false,
      onBlur: _ctx.forceUpdate,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInput('major', $event)))
    }, null, 8, ["modelValue", "onBlur"]),
    _createVNode(_component_el_input_number, {
      class: "verion-input__item",
      controls: false,
      min: 0,
      max: 999,
      modelValue: _ctx.localValue.minor,
      onBlur: _ctx.forceUpdate,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleInput('minor', $event)))
    }, null, 8, ["modelValue", "onBlur"]),
    _createVNode(_component_el_input_number, {
      class: "verion-input__item",
      controls: false,
      modelValue: _ctx.localValue.patch,
      min: 0,
      max: 999,
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleInput('patch', $event))),
      onBlur: _ctx.forceUpdate
    }, null, 8, ["modelValue", "onBlur"]),
    _createElementVNode("span", null, " 上一个版本号: " + _toDisplayString(_ctx.lastVersion), 1)
  ]))
}