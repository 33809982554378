import { templateEnum, appLevelEnum, appTemplateTypes, mainAppTemplateTypes } from './app-data-utils';
/**
 * 获取服务显示名称
 * @param name
 * @returns
 */
export const getServiceShowName = (name: string) => {
  const names = String(name || '').split(/\./gm);
  return names[names.length - 1] || name;
};

/**
 * 获取代码模版名称
 * @param name
 * @returns
 */
export const getTemplateTShowName = (appInfo: any) => {
  const { level, templateType } = appInfo;
  const isMainApp = level === appLevelEnum.MAIN_APP;
  const templates = isMainApp ? mainAppTemplateTypes : appTemplateTypes;
  const template = templates.find((i: any) => i.code === templateType);
  return template?.name;
};

/**
 * 获取模版显示图标
 * @param templateType
 * @returns
 */
export const getTemplateTIcon = (templateType: number) => {
  switch (templateType) {
    case templateEnum.PC_TEMPLATE:
      return 'el-icon-s-platform';
    case templateEnum.H5_TEMPLATE:
      return 'el-icon-mobile-phone';
    default:
      return 'el-icon-s-platform';
  }
};
