
import { computed, defineComponent, PropType, reactive, watch, ref } from 'vue';
import { parse, ReleaseType } from './version';
import { DEFAULT_VERSION } from './release';
import { useForceUpdate } from './useVerionInput';
import { ElMessage } from 'element-plus';
export default defineComponent({
  name: 'VersionInput',
  props: {
    lastVersion: {
      type: String as PropType<string>,
      default: '',
    },
    modelValue: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, ctx) {
    const localValue = ref({
      major: 1,
      minor: 0,
      patch: 0,
    });

    const currentVersion = computed(
      () => `v${localValue.value.major}.${localValue.value.minor}.${localValue.value.patch}`,
    );
    const init = () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const semVer = parse(props.lastVersion) ?? parse(DEFAULT_VERSION)!;
      if (props.lastVersion) {
        semVer.inc('patch');
      }
      let { major, minor, patch } = semVer;
      if (patch >= 999) {
        patch = 0;
        minor += 1;
      }
      if (minor >= 999) {
        major += 1;
        minor = 0;
      }
      if (major > 999) {
        patch = 999;
        minor = 999;
        major = 999;
        ElMessage.error('当前版本已经超出限制');
      }
      localValue.value = reactive({
        major,
        minor,
        patch,
      });

      ctx.emit('update:modelValue', currentVersion);
    };

    init();

    watch(
      () => props.lastVersion,
      () => {
        init();
      },
    );

    const handleInput = (type: ReleaseType, value: string | undefined) => {
      if (value === undefined) return;

      const identifier = parseInt(value, 10);

      if (Math.sign(identifier) === -1) return;
      if (type === 'major') {
        localValue.value.major = identifier;
      }
      if (type === 'minor') {
        localValue.value.minor = identifier;
      }
      if (type === 'patch') {
        localValue.value.patch = identifier;
        console.log(identifier, 1111);
      }
      ctx.emit('update:modelValue', currentVersion);
    };
    return {
      localValue,
      handleInput,
      ...useForceUpdate(),
    };
  },
});
