import { checkRefrenceServiceBySource } from '@/views/service-management/business-service/utils/permisson';

import { computed, provide, Ref, InjectionKey, readonly, ComputedRef } from 'vue';

import { App } from './type';
export const REFRENCE_APP: InjectionKey<Readonly<ComputedRef<boolean>>> = Symbol('is-refrence-app');

export function useRefrenceApp(curApp: Ref<App>) {
  const isRefrenceApp = computed(() => checkRefrenceServiceBySource(curApp?.value?.serviceSource));

  const isDisableSync = computed(() => isRefrenceApp.value && curApp?.value?.initTimes > 0);

  provide(REFRENCE_APP, readonly(isRefrenceApp));

  return {
    isRefrenceApp,
    isDisableSync,
  };
}
