import { ref } from 'vue';
import { getAppDetailById } from '@/api/app';

const data = {
  name: '英文名',
  description: '中文名',
};
// 应用基础数据
export const baseData = ref(data) as any;

// 应用输入数据
export const inputData = ref(baseData.value);

// 当前应用对象
export const currentApp = ref({}) as any;

// 获取当前应用信息
export const getAppDetail = (id: string) => {
  getAppDetailById(id)
    .then((res) => {
      currentApp.value = res.data;
    })
    .catch((e) => {
      console.log(e);
      currentApp.value = {};
    });
};
export async function getDetailApp(id: string) {
  const { data } = await getAppDetailById(id);
  currentApp.value = data || {};
  return data;
}
